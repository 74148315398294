import React from "react";

const CodeDisplay = ({ code }) => {
  const jsonStringWithoutBackslashes = JSON.stringify(code);
  const inner_content = JSON.parse(jsonStringWithoutBackslashes);

  if (inner_content) {
    return (
      <>
        <div
          className="codewrap"
          dangerouslySetInnerHTML={{
            __html: inner_content,
          }}
        />
      </>
    );
  }
};

export default CodeDisplay;