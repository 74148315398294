"use client";
import Image from "next/image";
import Link from "next/link";
import https from "https";
import styles from "@/app/styles/header.module.scss";
import { usePathname } from "next/navigation";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CodeDisplay from "@/app/utils/content";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const agent = new https.Agent({
  rejectUnauthorized: false,
});

async function getPosts() {
  const query = `
  {
  menu(id: "dGVybToy") {
    id
    name
    menuItems {
      nodes {
        id
        label
        cssClasses
        menuItemId
        uri
        url
        target
      }
    }
  }
  globalSettings {
    globalSettingsNew {
      smFacebook
      smInstagram
      tripadvisor
      reservationLink
    }
  }
}
  `;

  const res = await fetch(
    `${process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT}`,
    {
      httpsAgent: agent,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({query}),
      next: {
        revalidate: 60,
      },
    }
  );

  const { data } = await res.json();

  return data;
}

export default function Header() {

  const [page_data, setPageData] = useState(null);

  const pathname = usePathname();
  const [menu_items, setMenuItems] = useState(null);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [fb_link, setFbLink] = useState('');
  const [insta_link, setInstaLink] = useState('');
  const [advisor_link, setAdvisorLink] = useState('');

  const [showBookModal, setShowBookModal] = useState(false);
  const [bookUrl, setBookUrl] = useState('#');

  const openBookModal = (url) => {
     setShowBookModal(true);
   };
 
   const closeBookModal = () => {
     setShowBookModal(false);
   };

  const normalizePath = (path) => {
    return path.replace(/\/+$/, "");
  };

  useEffect(() => {
    const fetchData = async () => {
      const page = await getPosts();

      if (page) {
        //console.log(page);
        setPageData(page);
        if (page.menu) {
          setMenuItems(page.menu);
        }
        if (page.globalSettings) {
          if (page.globalSettings.globalSettingsNew) {
            if (page.globalSettings.globalSettingsNew.smFacebook) {
              setFbLink(page.globalSettings.globalSettingsNew.smFacebook);
            }
            if (page.globalSettings.globalSettingsNew.smInstagram) {
              setInstaLink(page.globalSettings.globalSettingsNew.smInstagram);
            }
            if (page.globalSettings.globalSettingsNew.tripadvisor) {
              setAdvisorLink(page.globalSettings.globalSettingsNew.tripadvisor);
            }
            if (page.globalSettings.globalSettingsNew.reservationLink) {
              setBookUrl(page.globalSettings.globalSettingsNew.reservationLink);
            }
          }
          
        }
      }
    };

    fetchData();
    return () => {};
  }, []);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const handleProgressEvent = (e) => {
  //       const { target, progress } = e.detail;
  //      // console.log(`target: ${target}`, `progress: ${progress}`);
  //       setProgress(progress);
  //     };

  //     const handleScroll = () => {
  //       const currentScrollY = window.scrollY;
  //       if (currentScrollY > lastScrollY) {
  //         setScrollDirection("down");
  //       } else if (currentScrollY < lastScrollY) {
  //         setScrollDirection("up");
  //       }
  //       setLastScrollY(currentScrollY);
  //     };

  //     window.addEventListener("progressEvent", handleProgressEvent);
  //     window.addEventListener("scroll", handleScroll);

  //     // Clean up the event listeners on component unmount
  //     return () => {
  //       window.removeEventListener("progressEvent", handleProgressEvent);
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [lastScrollY]);

  // useEffect(() => {
  //   const header = document.querySelector("header");
  //   if (header) {
  //     if (scrollDirection === "up") {
  //       header.classList.add("header-scrolled-up");
  //     } else {
  //       header.classList.remove("header-scrolled-up");
  //     }
  //     if (progress === 1) {
  //       header.classList.add("header-scrolled");
  //     } else {
  //       header.classList.remove("header-scrolled");
  //     }
  //   }
  // }, [scrollDirection, progress]);

  useEffect(() => {
    const initializeScroll = async () => {
      const LocomotiveScroll = (await import("locomotive-scroll")).default;
      const locomotiveScroll = new LocomotiveScroll({
        scrollCallback: onScroll
      });

    };

    initializeScroll();

    function onScroll({ direction, progress }) {
      //console.log(direction, progress);

      const header = document.querySelector("header");
        if (header) {
          // if (direction === 1) {
          //   header.classList.add("header-scrolled-up");
          // } else {
          //   header.classList.remove("header-scrolled-up");
          // }
          if (progress > 0.05) {
            header.classList.add("header-scrolled");
          } else {
            header.classList.remove("header-scrolled");
          }
        }

    }
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  if (!page_data) {
    return <div></div>;
  }

  return (
    <>
      {isOpen && <div className="black-overlay" onClick={handleOverlayClick}></div>}
      <header
        className={`${styles.topmenu} header`}
        data-scroll
        data-scroll-event-progress="progressEvent"
      >
        <div className={`main-short-wrapper main-wrapper-head d-flex justify-content-between  align-items-center ${isOpen ? 'open' : ''}`}>
          <div className="mobile_el flex-grow-1">
            <Link href="/">
              <Image   src="/logo-gif.svg" width={130} height={40} alt="seen" />
            </Link>
          </div>
          <div className="desktop_el menu-logo-wrap-div">
            <div className={styles.mainmenu_logo}>
              <Link href="/">
                <Image   src="/logo-gif.svg" width={130} height={40} alt="seen" />
              </Link>
            </div>
            <nav className={`${styles.main_menu} navbar navbar-expand-lg`}>
              <ul className="navbar-nav">
                {menu_items &&
                  menu_items.menuItems &&
                  menu_items.menuItems.nodes.map((menuItem) => (
                    <li
                      key={menuItem.id}
                      className={`nav-item ${
                        normalizePath(pathname) === normalizePath(menuItem.uri)
                          ? "active"
                          : ""
                      }`}
                    >
                      {menuItem.target === "_blank" ? (
                          <a 
                          href={menuItem.target === "_blank" 
                            ? menuItem.url 
                            : `${process.env.APP_URL}${menuItem.uri}`
                          } 
                          target={menuItem.target === "_blank" ? "_blank" : undefined}
                          rel={menuItem.target === "_blank" ? "noopener noreferrer" : undefined}
                          >
                            <span>{menuItem.label}</span>
                            <div
                              className={`next-btn-bottom-menu next-btn next-btn--yellow w-100`}
                            >
                              <div className="next-btn--bottom d-flex w-100">
                                <span className="next-btn--bottom-line"></span>
                                <span className="next-btn--bottom-square"></span>
                                <span className="next-btn--bottom-line"></span>
                              </div>
                            </div>
                          </a>
                          ) : (
                            // Internal link using Next.js <Link>
                            <Link href={`${process.env.APP_URL}${menuItem.uri}`} passHref>
                                <span>{menuItem.label}</span>
                                <div className="next-btn-bottom-menu next-btn next-btn--yellow w-100">
                                  <div className="next-btn--bottom d-flex w-100">
                                    <span className="next-btn--bottom-line"></span>
                                    <span className="next-btn--bottom-square"></span>
                                    <span className="next-btn--bottom-line"></span>
                                  </div>
                                </div>
                            </Link>
                          )}
                    </li>
                  ))}
              </ul>
            </nav>
          </div>
          <div className="tab_desktop_el reservation-btn">
            <button className="next-btn next-btn--yellow" onClick={() => openBookModal()}>
              <span className="next-btn--text">reservations</span>
              <div className="next-btn--bottom">
                <span className="next-btn--bottom-line"></span>
                <span className="next-btn--bottom-square"></span>
                <span className="next-btn--bottom-line"></span>
              </div>
            </button>
          </div>
          <div className="mobile_el">
            <button className={`${styles.toggle_btn} ham ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
        <div className={`${styles.scrollmenu} scrollmenu`}>
          <div className="d-flex main-wrapper justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className={styles.scrollmenu_logo}>
                <Link href="/">
                  <Image   src="/logo-gif.svg" width={130} height={40} alt="seen" />
                </Link>
              </div>
              <div className="desktop_el">
                <nav className={`${styles.main_menu} navbar navbar-expand-lg`}>
                  <ul className="navbar-nav">
                    {menu_items &&
                      menu_items.menuItems &&
                      menu_items.menuItems.nodes.map((menuItem) => (
                        <li
                          key={menuItem.id}
                          className={`nav-item ${
                            normalizePath(pathname) ===
                            normalizePath(menuItem.uri)
                              ? "active"
                              : ""
                          }`}
                        >
                          {menuItem.target === "_blank" ? (
                          <a 
                          href={menuItem.target === "_blank" 
                            ? menuItem.url 
                            : `${process.env.APP_URL}${menuItem.uri}`
                          } 
                          target={menuItem.target === "_blank" ? "_blank" : undefined}
                          rel={menuItem.target === "_blank" ? "noopener noreferrer" : undefined}
                          >
                            <span>{menuItem.label}</span>
                            <div
                              className={`next-btn-bottom-menu next-btn next-btn--yellow w-100`}
                            >
                              <div className="next-btn--bottom d-flex w-100">
                                <span className="next-btn--bottom-line"></span>
                                <span className="next-btn--bottom-square"></span>
                                <span className="next-btn--bottom-line"></span>
                              </div>
                            </div>
                          </a>
                          ) : (
                            // Internal link using Next.js <Link>
                            <Link href={`${process.env.APP_URL}${menuItem.uri}`} passHref>
                                <span>{menuItem.label}</span>
                                <div className="next-btn-bottom-menu next-btn next-btn--yellow w-100">
                                  <div className="next-btn--bottom d-flex w-100">
                                    <span className="next-btn--bottom-line"></span>
                                    <span className="next-btn--bottom-square"></span>
                                    <span className="next-btn--bottom-line"></span>
                                  </div>
                                </div>
                            </Link>
                          )}
                        </li>
                      ))}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="d-flex">
              <div className="tab_desktop_el reserve-top padding-right">
                <button className="next-btn next-btn--yellow" onClick={() => openBookModal()}>
                  <span className="next-btn--text">reservations</span>
                  <div className="next-btn--bottom">
                    <span className="next-btn--bottom-line"></span>
                    <span className="next-btn--bottom-square"></span>
                    <span className="next-btn--bottom-line"></span>
                  </div>
                </button>
              </div>
              <div className="mobile_el">
                <button className={`${styles.toggle_btn} ham ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={`${styles.menu} ${isOpen ? styles.menuOpen : ''} menu-nav-slide`}>
        <nav className={`${styles.main_menu} navbar navbar-expand-lg`}>
          <ul className="navbar-nav">
            {menu_items &&
              menu_items.menuItems &&
              menu_items.menuItems.nodes.map((menuItem, index) => (
                <li
                  key={index}
                  className={`nav-item ${
                    normalizePath(pathname) === normalizePath(menuItem.uri)
                      ? "active"
                      : ""
                  }`}
                  onClick={handleLinkClick}
                >
                  {menuItem.target === "_blank" ? (
                    <a 
                    href={menuItem.target === "_blank" 
                      ? menuItem.url 
                      : `${process.env.APP_URL}${menuItem.uri}`
                    } 
                    target={menuItem.target === "_blank" ? "_blank" : undefined}
                    rel={menuItem.target === "_blank" ? "noopener noreferrer" : undefined}
                    >
                      <span>{menuItem.label}</span>
                      <div
                        className={`next-btn-bottom-menu next-btn next-btn--yellow w-100`}
                      >
                        <div className="next-btn--bottom d-flex w-100">
                          <span className="next-btn--bottom-line"></span>
                          <span className="next-btn--bottom-square"></span>
                          <span className="next-btn--bottom-line"></span>
                        </div>
                      </div>
                    </a>
                    ) : (
                      // Internal link using Next.js <Link>
                      <Link href={`${process.env.APP_URL}${menuItem.uri}`} passHref>
                          <span>{menuItem.label}</span>
                          <div className="next-btn-bottom-menu next-btn next-btn--yellow w-100">
                            <div className="next-btn--bottom d-flex w-100">
                              <span className="next-btn--bottom-line"></span>
                              <span className="next-btn--bottom-square"></span>
                              <span className="next-btn--bottom-line"></span>
                            </div>
                          </div>
                      </Link>
                    )}
                </li>
              ))}
          </ul>
        </nav>
        <ul className={styles.footer_social}>
          <li>
            <a target="_blank" href={fb_link}>
              <Image   src="/fb.png" width={30} height={30} alt="Facebook Icon" />
            </a>
          </li>
          <li>
            <a target="_blank" href={insta_link}>
              <Image  
                src="/insta.png"
                width={30}
                height={30}
                alt="Instagram Icon"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href={advisor_link}>
              <Image  
                src="/advi.png"
                width={30}
                height={30}
                alt="Advisor Icon"
              />
            </a>
          </li>
        </ul>
      </div>
      <button className="next-btn-fixed mobileonly_el" onClick={() => openBookModal()}>reservations</button>
      <Modal show={showBookModal} onHide={closeBookModal} className="book-box" centered scrollable backdrop={true}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="iframe-div">
            <CodeDisplay code={bookUrl} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}