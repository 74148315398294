'use client'
import Image from "next/image";
import Link from "next/link";
import styles from "@/app/styles/footer.module.scss";
import { useEffect, useState } from 'react';
import https from "https";
import useDeviceType from "@/app/utils/useDeviceType";

const agent = new https.Agent({
  rejectUnauthorized: false,
});

async function getPosts() {
  const query = `
 {
  globalSettings {
    globalSettingsNew {
      footerText
      openingHours
      smFacebook
      smInstagram
      tripadvisor
      footerLogoUrl
      footerLogo {
        node {
          mediaItemUrl
        }
      }
    }
    globalSettingsNewSmtp {
      addressLine1
      contactEmail
      contactName
      hotlineNumber
    }
  }
  menu(id: "dGVybToz") {
    id
    name
    menuItems {
      nodes {
        id
        label
        cssClasses
        menuItemId
        uri
        url
        target
      }
    }
  }
}
  `;

  const res = await fetch(
    `${process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT}`,
    {
      httpsAgent: agent,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({query}),
      next: {
        revalidate: 60,
      },
    }
  );

  const { data } = await res.json();

  return data;
}

const Footer = () => {

  const [page_data, setPageData] = useState(null);

  const [menu_items, setMenuItems] = useState(null);
  const [global_setting, setGlobalSetting] = useState(null);
  const [footer_text, setFooterText] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [email_address, setEmailAddress] = useState('');
  const [main_address, setMainAddress] = useState('');
  const [opening_hours, setOpeningHours] = useState('');
  const [fb_link, setFbLink] = useState('');
  const [insta_link, setInstaLink] = useState('');
  const [advisor_link, setAdvisorLink] = useState('');
  const [currentYear, setCurrentYear] = useState(null);
  const [footer_logo, setFooterLogo] = useState(null);
  const [footer_logo_url, setooterLogoUrl] = useState(null);

  const deviceType = useDeviceType();

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const page = await getPosts();

      if (page) {
        setPageData(page);
      //  console.log(page);
        if (page.menu) {
          setMenuItems(page.menu);
        }
        if (page.globalSettings) {
          if (page.globalSettings.globalSettingsNew) {
            setGlobalSetting(page.globalSettings.globalSettingsNew);

            if (page.globalSettings.globalSettingsNew.footerText) {
              setFooterText(page.globalSettings.globalSettingsNew.footerText);
            }
            if (page.globalSettings.globalSettingsNew.openingHours) {
              setOpeningHours(page.globalSettings.globalSettingsNew.openingHours);
            }
            if (page.globalSettings.globalSettingsNew.smFacebook) {
              setFbLink(page.globalSettings.globalSettingsNew.smFacebook);
            }
            if (page.globalSettings.globalSettingsNew.smInstagram) {
              setInstaLink(page.globalSettings.globalSettingsNew.smInstagram);
            }
            if (page.globalSettings.globalSettingsNew.tripadvisor) {
              setAdvisorLink(page.globalSettings.globalSettingsNew.tripadvisor);
            }
            if (page.globalSettings.globalSettingsNew.footerLogo) {
              if (page.globalSettings.globalSettingsNew.footerLogo.node) {
                if (page.globalSettings.globalSettingsNew.footerLogo.node.mediaItemUrl) {
                  setFooterLogo(page.globalSettings.globalSettingsNew.footerLogo.node.mediaItemUrl);
                }
              }
            }
            if (page.globalSettings.globalSettingsNew.footerLogoUrl) {
              setooterLogoUrl(page.globalSettings.globalSettingsNew.footerLogoUrl);
            }
          }
          if (page.globalSettings.globalSettingsNewSmtp) {
            setGlobalSetting(page.globalSettings.globalSettingsNewSmtp);

            if (page.globalSettings.globalSettingsNewSmtp.addressLine1) {
              setMainAddress(page.globalSettings.globalSettingsNewSmtp.addressLine1);
            }
            if (page.globalSettings.globalSettingsNewSmtp.contactEmail) {
              setEmailAddress(page.globalSettings.globalSettingsNewSmtp.contactEmail);
            }
            if (page.globalSettings.globalSettingsNewSmtp.hotlineNumber) {
              setPhoneNumber(page.globalSettings.globalSettingsNewSmtp.hotlineNumber);
            }
          }
        }
      }
    };

    fetchData();
    return () => {};
  }, []);

  
  if (!page_data) {
    return <div></div>;
  }

  return (
    <>
      <footer className={styles.footer_main}>
        <div className={styles.footer}>
          <div className={`${styles.footer_top} middle-wrap index-top`}>
            <h4 className="section-heading section-heading--yellow heading-padding text-center">
              {footer_text}
            </h4>
            <div className="next-btn next-btn--yellow m-auto">
              <div className="next-btn--bottom">
                <span className="next-btn--bottom-line"></span>
                <span className="next-btn--bottom-square"></span>
                <span className="next-btn--bottom-line"></span>
              </div>
            </div>
            <div
              className={`${styles.footer_col_wrap} d-flex flex-wrap justify-content-between`}
            >
              <div className={`${styles.footer_col} mobile_el`}>
                <p className="paragraph-small paragraph--white text-center">
                  {main_address}
                </p>
              </div>
              <div className={styles.footer_col}>
                <ul>
                  <li className="paragraph-small paragraph--white">
                    <span>Email : </span>
                    <a href={`mailto:${email_address}`}>{email_address}</a>
                  </li>
                  <li className="paragraphsmall paragraph--white">
                    <span> Phone : </span>
                    <a href={`tel:${phone_number}`}>{phone_number}</a>
                  </li>
                </ul>
                <ul className={styles.footer_social}>
                  <li>
                    <a target="_blank" href={fb_link}>
                      <Image   src="/fb.png" width={50} height={50} alt="Facebook Icon" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={insta_link}>
                      <Image  
                        src="/insta.png"
                        width={50}
                        height={50}
                        alt="Instagram Icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={advisor_link}>
                      <Image  
                        src="/advi.png"
                        width={50}
                        height={50}
                        alt="Advisor Icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className={`${styles.footer_col} desktop_el`}>
                <p className="paragraph-small paragraph--white text-center">
                  {main_address}
                </p>
              </div>
              <div className={`${styles.footer_col} ${styles.footer_col_last}`}>
                <ul>
                  <li className="paragraph-small paragraph--white para-bold">
                  SEEN&apos;s Opening Hours
                  </li>
                  <li className="paragraphsmall paragraph--white">
                    {opening_hours}
                  </li>
                </ul>
                {footer_logo &&(
                <a target="_blank" href={footer_logo_url}>
                  <Image   src={footer_logo} width={80} height={40} alt="Avani Logo" />
                </a>
                )}
              </div>
            </div>
          </div>
          <div className={styles.footer_bottom}>
            <div className="middle-wrap flex-wrap d-flex justify-content-between align-items-center index-top">
              <div className={styles.footer_col_first}>
                <ul>
                  {menu_items &&
                    menu_items.menuItems &&
                    menu_items.menuItems.nodes.map((menuItem, index) => (
                      <li key={index}>
                         {menuItem.target === "_blank" ? (
                          <a 
                          href={menuItem.target === "_blank" 
                            ? menuItem.url 
                            : `${process.env.APP_URL}${menuItem.uri}`
                          } 
                          target={menuItem.target === "_blank" ? "_blank" : undefined}
                          rel={menuItem.target === "_blank" ? "noopener noreferrer" : undefined}
                          >
                            <span>{menuItem.label}</span>
                            
                          </a>
                          ) : (
                            // Internal link using Next.js <Link>
                            <Link href={`${process.env.APP_URL}${menuItem.uri}`} passHref>
                                <span>{menuItem.label}</span>
                                
                            </Link>
                          )}
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div className={styles.footer_col_second}>
                <p className="text-center m-0">
                  © {currentYear} SEEN Restaurant & Bar. All rights reserved.
                </p>
              </div>
              <div className={styles.footer_col_third}>
                <p className="text-right m-0">
                  Website Designed & Developed by
                  <a target="_blank" href="https://www.emarketingeye.com" rel="noopener noreferrer">eMarketingEye</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.invisible_div}></div>
      </footer>
    </>
  );
};

export default Footer;